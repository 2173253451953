.logo-img {
    width: 3.5vw;
    border-radius: 100px;
    background-color: rgb(218, 218, 218);
    margin-right: 15px;
}

.product-count{
    position: absolute;
    right: 35px;
    top: 20px;
    background-color: rgb(204, 6, 6);
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}