.home-items-container {
    padding: 0 50px; /* Espaciado alrededor de la cuadrilla */
    background-color: #f9f9f9; /* Fondo claro */
}

.module-title {
    text-align: center; /* Centrar el título */
    font-size: 2rem; /* Tamaño de la fuente del título */
    margin-bottom: 5px; /* Espaciado debajo del título */
    color: #333; /* Color del texto del título */
}

.grid {
    display: flex;
    flex-wrap: wrap; /* Permitir que las columnas se ajusten en nuevas filas */
    margin: 0 3vw;
}

.col-3 {
    flex: 0 0 25%; /* 4 columnas a lo ancho (25% cada una) */
    padding: 15px 30px; /* Espaciado interno entre las cards */
}

.item-card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centrar contenido en la card */
    align-items: start; /* Centrar contenido en la card */
    padding: 20px 0px; /* Espaciado interno de la card */
    text-align: start; /* Centrar el texto */
    border-radius: 12px; /* Bordes redondeados */
    background-color: #ffffff; /* Fondo blanco para las cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    transition: transform 0.2s; /* Efecto de transición */
    height: 10vw;
    font-size: 0.8vw;
}

.item-card:hover {
    transform: translateY(2px); /* Efecto de elevación al pasar el ratón */
    cursor: pointer;
}

.icon-container {
    margin-bottom: 1vw; /* Espaciado entre el ícono y el texto */
    width: 2.5vw; /* Ancho del contenedor del ícono */
    height: 2.5vw; /* Alto del contenedor del ícono */
    display: flex; /* Alinear el ícono en el centro */
    justify-content: center; /* Centrar el ícono horizontalmente */
    align-items: center; /* Centrar el ícono verticalmente */
}