.client-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.client-value{
    border: 1px solid grey;
    padding: 5px 20px;
    border-radius: 100px;
    background-color: rgb(223, 223, 223);
}