.row-ingreso {
    background-color: #e6eee8; /* Verde claro */
    color: #155724;
}

.row-egreso {
    background-color: #f5e5e6; /* Rojo claro */
    color: #721c24;
}

.row-reserva {
    background-color: #fff9e4; /* Amarillo claro */
    color: #856404;
}


.form-container-transactions {
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan */
    gap: 15px; /* Espacio entre los inputs */
}

.form-group-transactions {
    display: flex;
    flex-direction: column;
    flex: 1 1 10%; /* Permitir que los elementos ocupen un ancho flexible */
    padding: 0 1%;
}

.form-label-transactions {
    margin-bottom: 5px; /* Espacio entre la etiqueta y el campo de entrada */
    color: #333; /* Color del texto de la etiqueta */
    font-size: 14px; /* Tamaño de fuente para la etiqueta */
}

.input-field-transactions {
    border-radius: 20px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris */
    font-size: 14px; /* Tamaño de fuente para el campo de entrada */
}

.dialog-footer-transactions {
    display: flex;
    justify-content: flex-end; /* Alinear botones a la derecha */
    margin-top: 20px; /* Espaciado superior */
}

.disabled-input-transactions {
    background-color: #e9ecef; /* Color gris claro */
    color: #495057; /* Color del texto */
    pointer-events: none; /* Desactiva la interacción del mouse */
}

.custom-row-transactions {
    height: 50px; /* Ajusta el valor según lo necesites */
    display: flex; /* Asegura que el contenido se alinee correctamente */
    align-items: center; /* Centra el contenido verticalmente */
    gap: 10px; /* Espacio entre los elementos */
}

/* Clase para filas con método de pago 'Efectivo' */
.row-efectivo {
    background-color: #7fb3d5; /* Azul claro */
    color: #fff;
}

/* Clase para filas con método de pago 'Tarjeta de Credito' */
.row-tarjeta {
    background-color: #7dcea0; /* Verde claro */
    color: #fff;
}

/* Clase para filas con método de pago 'Transferencia' */
.row-transferencia {
    background-color: #f0b27a; /* Naranja claro */
    color: #fff;
}

.white-button {
    color: #fff !important; /* Cambia el texto a negro si es necesario */
}

.white-button:hover {
    background-color: #f0f0f0 !important; /* Color más oscuro en hover */
    color: #000 !important;
}