.reset-password-container {
    max-width: 400px;
    margin: auto;
    padding: 2rem;
}

.title-reset {
    text-align: center;
    margin-bottom: 1.5rem;
}

.reset-password-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-field {
    margin-bottom: 1rem;
    width: 100%;
}
