/* appSidebar.css */
.sidebar {
    background: #272727; /* Fondo negro */
    color: #fff; /* Texto blanco */
    width: 17vw;
}

.sidebar .p-sidebar-content{
    margin: 0px !important;
    padding: 0 !important;
}

.sidebar .p-sidebar-header{
    margin: 0px !important;
    padding: 0 !important;
}

.sidebar-header {
    padding: 20px; /* Espaciado interno */
    background: #1A1A1A; /* Fondo de la cabecera */
    color: #fff; /* Color del texto */
}



.menu-item {
    color: #fff; /* Color del texto de los ítems */
    padding: 15px 15px;
    margin: 0;
    border-radius: 15px;
    cursor: pointer;
}

.item-home {
    border-radius: 0px;
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}

.menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Fondo al pasar el ratón */
}

.sidebar-footer {
    padding: 15px 10px; /* Espaciado interno */
    background: #1A1A1A; /* Fondo del pie */
    display: flex;
    align-items: center;
    justify-content: center;
}


/* appSidebar.css */
.custom-button-back {
    background-color: #fff;
    color: black;
    padding: 0rem; /* Ajusta el padding para hacer el botón más pequeño */
    font-size: 1rem; /* Ajusta el tamaño de la fuente */
    border-radius: 50%; /* Esto asegura que siga siendo redondeado */
    width: 30px;
    height: 30px;
}


.user-component {
    position: relative; /* Permitir que el menú desplegable se posicione en relación a este contenedor */
}

.dropdown-menu {
    position: absolute;
    right: 0; /* Alinear a la derecha */
    background-color: #ffffff; /* Fondo blanco */
    border: 1px solid #ccc; /* Borde gris */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    z-index: 1000; /* Asegurarse de que esté por encima de otros elementos */
}

.dropdown-menu ul {
    list-style: none; /* Eliminar el estilo de lista */
    padding: 10px 0; /* Espaciado interno */
    margin: 0; /* Eliminar márgenes */
    background-color: #8d8d8d; /* Color de fondo */
}

.dropdown-menu li {
    padding: 10px 20px; /* Espaciado interno para los elementos */
    cursor: pointer; /* Cambiar el cursor al pasar el ratón */
}

.dropdown-menu li:hover {
    background-color: #7e7e7e; /* Cambiar el color de fondo al pasar el ratón */
}

/* Añadir clases para manejar la dirección del dropdown */
.dropdown-up {
    bottom: 100%; /* Coloca el dropdown encima del botón */
    margin-bottom: 10px; /* Espacio entre el botón y el dropdown */
}

.dropdown-down {
    top: 100%; /* Coloca el dropdown debajo del botón */
    margin-top: 10px; /* Espacio entre el botón y el dropdown */
}
