.input-filter-budgets {
    margin: 0 30px;
    border-radius: 100px;
}

.form-container-budgets {
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan */
    gap: 15px; /* Espacio entre los inputs */
}

.form-group-budgets {
    display: flex;
    flex-direction: column;
    flex: 1 1 22%;
    padding: 0 1%;
}

.form-label-budgets {
    margin-bottom: 5px; /* Espacio entre la etiqueta y el campo de entrada */
    color: #333; /* Color del texto de la etiqueta */
    font-size: 14px; /* Tamaño de fuente para la etiqueta */
}

.input-field-budgets {
    border-radius: 20px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris */
    font-size: 14px; /* Tamaño de fuente para el campo de entrada */
}

.dialog-footer-budgets {
    display: flex;
    justify-content: flex-end; /* Alinear botones a la derecha */
    margin-top: 20px; /* Espaciado superior */
}

.disabled-input-budgets {
    background-color: #e9ecef; /* Color gris claro */
    color: #495057; /* Color del texto */
    pointer-events: none; /* Desactiva la interacción del mouse */
}

.custom-row-budgets {
    height: 50px; /* Ajusta el valor según lo necesites */
    display: flex; /* Asegura que el contenido se alinee correctamente */
    align-items: center; /* Centra el contenido verticalmente */
}
