.user-component {
    position: relative; /* Permitir que el menú desplegable se posicione en relación a este contenedor */
}

.dropdown-menu {
    position: absolute;
    right: 0; /* Alinear a la derecha */
    background-color: #ffffff; /* Fondo blanco */
    border: 1px solid #ccc; /* Borde gris */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    z-index: 1000; /* Asegurarse de que esté por encima de otros elementos */
}

.dropdown-menu.up {
    bottom: 100%; /* Posiciona el dropdown hacia arriba */
}

.dropdown-menu.down {
    top: 100%; /* Posiciona el dropdown hacia abajo */
}

.dropdown-menu ul {
    list-style: none; /* Eliminar el estilo de lista */
    padding: 10px 0; /* Espaciado interno */
    margin: 0; /* Eliminar márgenes */
}

.dropdown-menu li {
    padding: 10px 20px; /* Espaciado interno para los elementos */
    cursor: pointer; /* Cambiar el cursor al pasar el ratón */
}

.dropdown-menu li:hover {
    background-color: #e0e0e0; /* Cambiar el color de fondo al pasar el ratón */
}
