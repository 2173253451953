.p-field {
    margin-bottom: 1rem; /* Espacio entre los campos */
  }
  
  .input-field {
    width: 100%; /* Hacer que el campo de entrada ocupe todo el ancho */
  }
  
  .button-lg {
    width: 100%; /* Botón ocupa todo el ancho */
  }
  