/* login.css */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* Ocupa toda la altura de la ventana */
  background-color: #2e2e2e;
  /* Fondo negro */
  color: #fff;
  /* Texto blanco */
}

.logo {
  font-size: 2.5rem;
  margin-bottom: 20px;
  /* Espacio debajo del logo */
  text-align: center;
  /* Centrar el logo */
}

.login-box {
  background-color: #646464;
  padding: 20PX 40px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 25vw;
  text-align: center;
}

.title-login {
  font-size: 2.5vw;
  margin-bottom: 18px;
  margin-top: 0;
}

.subtitle-login {
  font-size: 1.5vw;
  margin-bottom: 18px;
  margin-top: 0;
}

.login-box h2 {
  font-size: 2vw;
  margin-bottom: 18px;
}

/* Estilo para los inputs */
.input-field {
  width: 100%;
  /* Ancho completo del contenedor */
  height: 2vw;
  margin-bottom: 18px;
  /* Espacio entre inputs */
  border-radius: 100px;
  /* Bordes redondeados */
  text-align: center;
  font-size: 1vw;
}

/* Estilo para el botón */
.button-login {
  width: 100%;
  /* Ancho completo del contenedor */
  height: 2vw;
  margin-bottom: 15px;
  /* Espacio entre inputs */
  border-radius: 100px;
  /* Bordes redondeados */
  text-align: center;
  font-size: 1vw;
}

.error-message{
  background-color: #e0cdcd;
  color: rgb(161, 39, 39);

}
.success-message{
  background-color: #c6d8ce;
  color: rgb(53, 122, 35);
}

.alert-message {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vw;
  margin-bottom: 15px;
  border-radius: 100px;
}

.alert-message i {
  margin-right: 5px;
}

.logo-login {
  width: 8vw;
}

.recover-password {
  color: rgb(25, 25, 204);
  cursor: pointer;
  margin-top: 10px;
}

.recover-password:hover {
  text-decoration: underline;
}