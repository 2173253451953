.form-group-client {
    display: flex;
    flex-direction: column;

    padding: 0 5%;
    width: 48%;
}

.form-label-client {
    margin-bottom: 5px; /* Espacio entre la etiqueta y el campo de entrada */
    font-weight: bold;
    color: #333; /* Color del texto de la etiqueta */
    font-size: 14px; /* Tamaño de fuente para la etiqueta */
}