.input-filter-providers{
    margin: 0 30px;
    border-radius: 100px;
}

.cart-button-providers {
    border-radius: 50%; /* Hacer el botón completamente circular */
    height: 50px; /* Definir altura */
    width: 50px; /* Definir anchura */
    display: flex; /* Usar flexbox para centrar el ícono */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
}

.form-container-providers {
    display: flex;
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan */
    gap: 15px; /* Espacio entre los inputs */
}

.form-group-providers {
    display: flex;
    flex-direction: column;
    flex: 1 1 35%;
    padding: 0 5%;
}


.form-label-providers {
    margin-bottom: 5px; /* Espacio entre la etiqueta y el campo de entrada */
    font-weight: bold;
    color: #333; /* Color del texto de la etiqueta */
    font-size: 14px; /* Tamaño de fuente para la etiqueta */
}

.input-field-providers {
    border-radius: 20px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris */
    font-size: 14px; /* Tamaño de fuente para el campo de entrada */
}


.dialog-footer-providers {
    display: flex;
    justify-content: flex-end; /* Alinear botones a la derecha */
    margin-top: 20px; /* Espaciado superior */
}

.disabled-input-providers {
    background-color: #e9ecef; /* Color gris claro */
    color: #495057; /* Color del texto */
    pointer-events: none; /* Desactiva la interacción del mouse */
}
.custom-row-providers {
    height: 50px; /* Ajusta el valor según lo necesites */
    display: flex; /* Asegura que el contenido se alinee correctamente */
    align-items: center; /* Centra el contenido verticalmente */
}
